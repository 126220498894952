/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


        // JavaScript to be fired on all pages
        var $ = jQuery;
        window.$ = jQuery;


        /*ANIMATE OBJECTS ON ENTER*/
        /*Created by Sean Cloney 09-01-2018*/
        //-animate elements-----------------------
        var elementsToAnimate = [
           // '.slide-up-text.one',
           // '.guide-image',
         ];
         gaterAnimatingElements();
         function gaterAnimatingElements(){
           for (var i=0;i < $('.animateMe').length; i++){
             elementsToAnimate.push($('.animateMe').eq(i));
           }
           //console.log('elementsToAnimate',elementsToAnimate)
         }

         function isScrolledIntoViewVideos(elem){
           if(elem && elem != undefined){
             var docViewTop = $(window).scrollTop();
             var docViewBottom = docViewTop + $(window).height();

             var elemTop = elem.offset().top;

               var elemBottom = elemTop + elem.height()+150;
               return ((elemBottom >= docViewTop) && (elemTop <= docViewBottom) && (elemBottom <= docViewBottom) && (elemTop >= docViewTop));
             }

           }

        function isScrolledIntoView(elem){
          if(elem && elem != undefined){
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();

            var elemTop = elem.offset().top;

              var elemBottom = elemTop + elem.height();
              return ((elemBottom >= docViewTop) && (elemTop <= docViewBottom) && (elemBottom <= docViewBottom) && (elemTop >= docViewTop));
            }

          }
          function isScrolledOutOfView(elem){
            if(elem && elem != undefined){
              var docViewTop = $(window).scrollTop();
              var docViewBottom = docViewTop + $(window).height();

              var elemTop = elem.offset().top;
              if(elemTop < docViewTop -90){
                return true;
              }
              if(elemTop > $(window).scrollTop() + $(window).height()){
                return true;
              }


            }
          }

          // $(window).scroll(function() {
          //
          //   // if($(window).scrollTop() > 90){
          //   //   if(!$('header.banner').hasClass('darken')){
          //   //    $('header.banner').addClass('darken');
          //   //   }
          //   // }
          //   // else{
          //   //   if($('header.banner').hasClass('darken')){
          //   //    $('header.banner').removeClass('darken');
          //   //   }
          //   // }
          //
          //
          //    for (var i=0; i<elementsToAnimate.length;i++){
          //      if(isScrolledIntoView(elementsToAnimate[i])){
          //        $(elementsToAnimate[i]).removeClass('animateMe');
          //      }
          //      // if(isScrolledOutOfView(elementsToAnimate[i])){
          //      //    $(elementsToAnimate[i]).addClass('animateMe');
          //      // }
          //
          //    }
          //
          // })

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // News page
    'news': {
      init: function() {

        // JavaScript to be fired on the news page

      },
      finalize: function() {
        // JavaScript to be fired on the news page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
